@use '@angular/material' as mat;

@mixin wizardLink($pseudoBottomBorderPosition: 'before') {
	@include link($pseudoBottomBorderPosition);

	@if ($pseudoBottomBorderPosition == 'before' or $pseudoBottomBorderPosition == 'after') {
		&:#{$pseudoBottomBorderPosition} {
			background: currentColor;
		}

		&:hover:#{$pseudoBottomBorderPosition} {
			opacity: 0.7;
		}
	}
}
