@use '@angular/material' as mat;

:root {
	@include mat.typography-hierarchy($laptop-base-typography-config);

	@include mobileDeviceAndMedia('<phone-lg') {
		@include mat.typography-hierarchy($phone-base-typography-config);
	}
}

b,
strong {
	font-weight: $font-weight-extra-bold;
}
