$font-family: 'Museo', 'Roboto', sans-serif;

$color-dark-primary-text      : #0F213C;
$color-faded-dark-primary-text: fade-out($color-dark-primary-text, 0.35);
$color-dark-secondary-text    : #363c4499;
$color-border                 : fade-out($color-dark-primary-text, 0.88);

$elevation-color: $color-dark-primary-text;

$root-container-max-width   : 1200px;
$root-container-padding-y-sm: 0;
$root-container-padding-x-sm: 0;
$root-container-padding-y   : 2.5rem;
$root-container-padding-x   : 2.5rem;
$root-container-padding-sm  : $root-container-padding-y-sm $root-container-padding-x-sm;
$root-container-padding     : $root-container-padding-y $root-container-padding-x;

$breakpoint-phone     : 375px; //iphone 6/7/8
$breakpoint-phone-lg  : 500px;

$wizard-page-card-padding: 1.2rem;
