:root {
	background: white;

	@include media('>=phone-lg') {
		background-image: url(/assets/wizard-background.png);
		background-size: cover;

		> body {
			backdrop-filter: blur($cdk-overlay-dark-backdrop-filter-blur);
			background: $cdk-overlay-dark-backdrop-background;
		}

		// intercom in mobile consumes the whole screen and with the backdrop filter prevents rendering intercom messenger
		&.intercom-mobile-messenger-active > body {
			backdrop-filter: none;
		}
	}

	// Utility class to apply basic styles and used as main mobile root container
	.main-page-content {
		display: flex;
		flex-flow: column;
		align-items: center;
		width: 100%;
		height: 100%;
		position: relative;

		@include media('<phone-lg') {
			border-radius: 0 !important;
		}

		@include mobileDeviceAndMedia('<phone-lg') {
			padding: 1.5rem 1.25rem !important;
		}
	}

	&.embedded {
		.main-page-content {
			padding: 1.5rem;
		}
	}
}
