@use '@angular/material' as mat;

a,
button {
	&.mat-button-base {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		min-width: auto;
		border-color: $color-border;
	}
}

.bp-round-button.mat-flat-button {
	font-weight: $font-weight-extra-bold;
	@include mat.typography-level($laptop-base-typography-config, button);

	@include mobileDeviceAndMedia('<phone-lg') {
		@include heightAndLineHeight(38px);
		@include mat.typography-level($phone-base-typography-config, button);
	}
}
