@use '@angular/material' as mat;

mat-form-field.mat-form-field-appearance-outline {
	@include mat.typography-level($laptop-base-typography-config, input);

	@include mobileDeviceAndMedia('<phone-lg') {
		@include mat.typography-level($phone-base-typography-config, input);
	}
}

mat-checkbox {
	.mat-checkbox-layout {
		white-space: unset;
	}

	.mat-checkbox-inner-container {
		margin-right: 0.5rem;

		@include media('<phone-lg') {
			@include size(14px);
		}

		.mat-checkbox-frame {
			border: 1px solid $color-border;
		}

		.mat-checkbox-frame,
		.mat-checkbox-background {
			@include media('<phone-lg') {
				border-radius: 3px;
			}
		}
	}
}
