.mat-card.wizard-card,
.wizard-card {
	overflow: hidden;

	&:not(.button-like-card) {
		@include media('<phone-lg') {
			box-shadow: none !important;
		}
	}

	@include media('>=phone-lg') {
		border-radius: $card-border-radius;
		box-shadow: $fancy-shadow !important;
	}

	&.main-page-content {
		@include media('>=phone-lg') {
			height: unset;
		}
	}

	&.wizard-page-card {
		max-width: 1200px;
		width: 100%;
		margin: 0 auto;
		padding: 0;
		display: flex;
		flex-flow: column;
		height: fit-content;

		@include mobileDeviceAndMedia('<phone-lg') {
			padding: 0;
			box-shadow: none !important;
		}

		> mat-progress-bar {
			@extend .progress-bar-top-sticked;
		}

		.wizard-page-card-header {
			padding: $wizard-page-card-padding;
			display: flex;
			align-items: center;
		}

		.wizard-page-card-content {
			margin: 0;

			@include media('>=phone-lg') {
				margin: 0 $wizard-page-card-padding;
			}
		}

		.wizard-page-card-footer {
			display: flex;
			flex-flow: column;
			align-items: center;
			justify-content: center;

			@include media('>=phone-lg') {
				padding: $wizard-page-card-padding;
				background: $color-catskill-white;
				border-top: 1px solid $color-border;
			}

			.mat-flat-button {
				width: 100%;

				@include media('>=phone-lg') {
					max-width: 300px;
				}
			}
		}
	}
}
