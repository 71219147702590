@use '@angular/material' as mat;

@mixin arrow-right() {
	content: '';
	background-color: currentColor;
	mask-image: url(/assets/arrow-right.svg);
	mask-size: contain;
	mask-repeat: no-repeat;
	width: 0.75em;
	height: 0.625em;
	display: inline-block;
}

.link {
	@include wizardLink('after');
}

.link-with-arrow-before {
	@include wizardLink('after');

	&:before {
		@include arrow-right();
		transform: rotate(180deg);
		margin-right: 0.3125em;
	}
}

.link-with-arrow-after {
	@include wizardLink('before');

	&:after {
		@include arrow-right();
		margin-left: 0.3125em;
	}
}
