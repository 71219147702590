form {
	.row {
		display: flex;
		gap: 1.5rem;

		> * {
			flex: 1;
			word-break: break-all;
		}

		> .control.w-100 > * {
			width: 100%;
		}

		> :not(:first-child) {
			margin-left: unset !important;

			&.control-type-switch {
				margin-top: 1rem;
			}
		}

		.control-type-button-toggle {
			margin-bottom: 26px;
		}
	}
}
